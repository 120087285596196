import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticStyle:{"height":"fit-content"}},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('span',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c(VSpacer),(_vm.activities.length > 0)?_c(VList,{staticClass:"transparent",attrs:{"three-line":""}},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"items-per-page":10,"group-by":"date","items":_vm.activities},scopedSlots:_vm._u([{key:`group.header`,fn:function({ group }){return [_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(group)+" ")])]}},{key:"item",fn:function({ item }){return [_c(VCard,{staticClass:"my-2"},[_c(VListItem,{on:{"click":() => {}}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":_vm.avatar.route}})]),_c(VListItemContent,{staticClass:"mx-3",staticStyle:{"font-size":"0.8rem"}},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.model)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.action)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.id)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s('Hora: ' + item.timestamp.split('T')[1].split('.')[0])}})],1)],1)],1)]}}],null,true)})],1):_c(VList,{staticClass:"transparent",attrs:{"three-line":""}},[_vm._l((10),function(i){return [_c(VSkeletonLoader,_vm._b({key:i,attrs:{"type":"list-item-avatar, divider"}},'v-skeleton-loader',_vm.attrs,false))]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }