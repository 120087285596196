import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticStyle:{"height":"fit-content"}},[_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":20,"no-data-text":"No se encontraron franquicias en el intervalo seleccionado","loading-text":"Buscando Franquicias","items-per-page-text":"Items por página","item-key":"name","disable-sort":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h3',[_vm._v("Nuevas Franquicias")]),_c(VRow,[_c(VCol,{staticClass:"m-1",attrs:{"cols":"","md":"4","lg":"4"}},[_c(VTextField,{attrs:{"type":"date","hide-details":"auto","label":"Desde"},model:{value:(_vm.firstDay),callback:function ($$v) {_vm.firstDay=$$v},expression:"firstDay"}})],1),_c(VCol,{staticClass:"m-1",attrs:{"cols":"","md":"4","lg":"4"}},[_c(VTextField,{attrs:{"type":"date","hide-details":"auto","label":"Hasta"},model:{value:(_vm.lastDay),callback:function ($$v) {_vm.lastDay=$$v},expression:"lastDay"}})],1),_c(VCol,{staticClass:"m-1",staticStyle:{"display":"flex","align-items":"flex-end"}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"px-1 py-1",attrs:{"color":"#003a56","dark":"","max-width":"50px","height":"40px"},on:{"click":function($event){$event.preventDefault();_vm.filterActivated = true
                  _vm.getCompanies()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])])],1)],1)]},proxy:true},{key:`item.categories`,fn:function({ item }){return _vm._l((item.categories),function(category,id){return _c(VChip,{key:id,staticClass:"m-1",attrs:{"color":"#003A56","outlined":"","pill":"","small":""}},[_vm._v(" "+_vm._s(category.name)+" ")])})}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }